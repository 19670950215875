import * as React from "react"
import styled, { keyframes } from "styled-components"
import "@fontsource/merriweather"
import "@fontsource/staatliches"
import { graphql, Link } from 'gatsby'
import Pill from "../components/pill"
import ProjectActionBar from "../components/project-action-bar";
import SHLHeader from "../components/SHL-header";
import Badge from "../components/badge";
import ResponsiveImage from "../components/responsive-image";
import {useState} from "react";
import CryptoJS from "crypto-js"
import InnerHTML from "dangerously-set-html-content"
import p5 from "p5"

// styles
const pageStyles = {
    fontFamily: "Roboto, sans-serif, serif",
    width: "auto",
    height: "auto",
    minHeight: "100vh",
    padding: "auto",
}
const contentStyles = {
    position: "relative",
    maxWidth: "768px",
    padding: "16px",
    margin: "auto",
    color: "#d8d8d8",
    backgroundColor: "#181818cc",
    marginBottom: "16px",
    borderRadius: "8px"
}
const FadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: none;
  }
`
const StyledLink = styled(props => <Link {...props} />)`
  display: inline-block;
  font-size: 1.2em;
  width: fit-content;
  color: #ff008c;
  transition: color 0.25s;
  :hover {
    color: #ffffff;
  }
  font-family: Merriweather, "Noto Serif TC", serif;
`
const Header2 = styled.h2`
  font-family: Merriweather, "Noto Serif TC", serif;
  font-size: 2em;
  margin: 0;
  color: #ffffff;
  hyphens: auto;
`
const Date = styled.div`
  color: #888888;
  font-family: Merriweather, serif;
  margin-bottom: 12px;
  font-size: 1em;
`
const Summary = styled.div`
  line-height: 1.5;
  font-size: 1.2em;
  font-family: Merriweather, serif;
  @media (min-width: 600px){
    text-align: justify;
    text-justify: newspaper;
  }
`
const TagContainer = styled.div`
  margin: 12px 0;
`
const Content = styled.div`
  font-family: Merriweather, "Noto Serif TC", serif;
  p {
    //text-indent: 2em;
    hyphens: auto;
    @media (min-width: 600px){
      text-align: justify;
      text-justify: newspaper;
    }
  }
  li{
    hyphens: auto;
    line-height: 1.8;
    margin: 0.25em 0;
  }
  a {
    color: #ff008c;
    transition: color 0.25s;
    height: 100%;
    :hover {
      color: white;
    }
  }
  animation: 0.25s ease-out 0s 1 FadeInDown;
`
const BottomNav = styled.div`
  display: flex;
  @media (max-width: 600px){
    flex-flow: column;
  }
  font-family: Merriweather, "Noto Serif TC", serif;
  color: #d8d8d8;
  font-size: 1em;
  width: 100%;
  margin-top: 3em;
`
const BottomNavNext = styled.div`
  position: relative;
  flex: 1;
  display: inline-block;
  @media (max-width: 600px){
    order: 1;
    text-align: center;
    ${StyledLink} {
      margin-bottom: 1em;
    }
  }
`
const BottomNavMid = styled.div`
  flex: 1;
  display: inline-block;
  @media (max-width: 600px){
    order: 3;
    //display: none;
  }
  text-align: center;
`
const BottomNavPrev = styled.div`
  position: relative;
  flex: 1;
  display: inline-block;
  text-align: right;
  @media (max-width: 600px){
    order: 2;
    text-align: center;
    ${StyledLink} {
      margin-bottom: 1em;
    }
  }
`
const BottomNavTag = styled.span`
  display: block;
  font-size: 0.9em;
  @media (min-width: 600px){
    position: absolute;
    right: 0;
    left: 0;
  }
  top: -1.3em;
`
const TableOfContents = styled.div`
  position: fixed;
  left: 20px;
  bottom: 20px;
  font-family: Merriweather, "Noto Serif TC", serif;
  p {
    margin: 0;
  }
  li{
    margin: 0.5em 0;
  }
  li::marker{
    color: #ff008c;
  }
  ul{
    padding-left: 1em;
  }
  ul > li > ul {
    font-size: 0.8em;
  }
`
const CashAnimation = keyframes`
  0% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0);
  }
`
const StripeAnim = keyframes`
  0%{
    background-position: 0 0;
  }
  100%{
    background-position: 0 32px;
  }
`
const DecryptPrompt = styled.form`
  color: #ff008c;
  font-family: 'Fira Code', monospace;
  text-align: center;
  padding: 4em 0;
  width: 100%;
  background: linear-gradient(135deg, #ff008c22 0, #ff008c22 25%,
  transparent 25%, transparent 50%,
  #ff008c22 50%, #ff008c22 75%,
  transparent 75%, transparent);
  background-size: 32px 32px;
  border-radius: 8px;
  border: 1px solid #ff008c;
  animation: ${StripeAnim} 1s infinite linear;

  h1 {
    color: #ff008c;
    margin-top: 0;

    ::before, ::after {
      display: inline-block;
      content: "🔒";
      margin: 0 0.25em;
      animation: ${CashAnimation} 1s infinite linear;
    }

    ::before {
      animation-direction: reverse;
    }
  }

  input {
    font-size: 1.2em;
    margin: 0 0.5em;
    border-radius: 4px;
    background: #181818cc;
    border: 1px solid #ff008c;
    color: #ff008c;
    padding: 0 0.2em;
    transition: background-color 0.25s;

    ::placeholder {
      font-family: 'Fira Code', monospace;
    }

    &[type=text] {
      font-family: 'Fira Code', monospace;
      margin-bottom: 0.2em;
      width: 10em;
    }

    &[type="submit"] {
      font-family: 'Fira Code', monospace;
      text-align: center;
      padding: 0 0.2em;

      :active {
        transform: translateY(0.1em);
      }
    }

    :focus-visible {
      outline: none;
      background: rgba(69, 0, 33, 0.8);
    }
  }
`
const FullContentWrapper = styled.div`
  animation: 0.25s ease-out 0s 1 FadeInDown;
`
// markup
const BlogPostPage = ({data}) => {
    const node = data.self.nodes[0];
    // the decrypted content
    const [decrypted, setDecrypted] = useState(node.fields.encrypted ? '' : node.html)
    // save user input
    const [password, setPassword] = useState('')

    const handleSubmit = (e) => {
        setDecrypted(CryptoJS.AES.decrypt(node.html, password).toString(CryptoJS.enc.Utf8));
        e.preventDefault();
    }
    const isBrowser = () => typeof window !== "undefined";
    if(isBrowser()){
        window.p5constructor = function (s){
            new p5(s);
        }
    }

    return (
        <main style={pageStyles}>
            <div style={contentStyles}>
                <title>{node.frontmatter.title + " | Blog | SH Liu | rarakasm"}</title>
                <SHLHeader subtitle="Blog" subtitleLink="/blog"/>
                {/*<TableOfContents dangerouslySetInnerHTML={{__html: node.tableOfContents}}/>*/}
                <FullContentWrapper>
                    <Header2>{node.frontmatter.title}</Header2>
                    {(node.frontmatter.badge && (
                        <Badge tag={node.frontmatter.badge}/>
                    ))}
                    <Date>{node.frontmatter.date}</Date>
                    <Summary>{node.frontmatter.summary}</Summary>
                    <TagContainer>
                        {
                            node.frontmatter.tags?.map((tag) =>
                                <Pill tag={tag}/>
                            )
                        }
                    </TagContainer>
                    {
                        decrypted && node.frontmatter.thumbnail && (
                            <ResponsiveImage src={node.frontmatter.thumbnail}/>
                        )
                    }
                    {
                        decrypted ?
                            (
                                <Content>
                                    <InnerHTML html={decrypted} dangerouslySetInnerHTML={{__html: decrypted}}/>
                                </Content>
                            )
                            :
                            (
                                <DecryptPrompt onSubmit={handleSubmit}>
                                    <h1>ENCRYPTED</h1>
                                    <input
                                        type="text"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        placeholder="Enter password"
                                    />
                                    <input type="submit" accessKey="Enter" value="Enter"/>
                                </DecryptPrompt>
                            )
                    }
                    <hr/>
                    <BottomNav>
                        <BottomNavNext>
                            {(data.next.nodes.length > 0 && (
                                <>
                                    <BottomNavTag>&lt; Newer</BottomNavTag>
                                    <StyledLink to={"/blog" + data.next.nodes[0].fields.slug}>{data.next.nodes[0].frontmatter.title}</StyledLink>
                                </>
                            ))}
                        </BottomNavNext>
                        <BottomNavMid>
                            <StyledLink to={"/blog"}>Back to blog</StyledLink>
                        </BottomNavMid>
                        <BottomNavPrev>
                            {(data.prev.nodes.length > 0 && (
                                <>
                                    <BottomNavTag>Older &gt;</BottomNavTag>
                                    <StyledLink to={"/blog" + data.prev.nodes[0].fields.slug}>{data.prev.nodes[0].frontmatter.title}</StyledLink>
                                </>
                            ))}
                        </BottomNavPrev>
                    </BottomNav>
                </FullContentWrapper>
            </div>
        </main>
    )
}

export default BlogPostPage


export const query = graphql`
    query BlogQuery($id: String!, $next: String, $prev: String) {
      self: allMarkdownRemark(filter: {id: {eq: $id}}) {
        nodes {
          id
          frontmatter {
            path
            date(formatString: "YYYY/MM/DD")
            link
            release
            repo
            summary
            tags
            thumbnail
            title
            video
            badge
          }
          fields{
            encrypted
          }
          html
          tableOfContents(pathToSlugField: "frontmatter.path", absolute: false)
        }
      }
      next: allMarkdownRemark(filter: {id: {eq: $next}}) {
        nodes {
          id
          frontmatter {
            title
            path
          }
          fields{
            slug
          }
        }
      }
      prev: allMarkdownRemark(filter: {id: {eq: $prev}}) {
        nodes {
          id
          frontmatter {
            title
            path
          }
          fields{
            slug
          }
        }
      }
    }
`
